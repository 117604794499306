import $ from 'jquery';
window.$ = window.jQuery = $;
import { Workbox } from 'workbox-window';
import { debounce } from 'throttle-debounce';
import Swal from 'sweetalert2';
import './sweetalert.js';

if ('serviceWorker' in navigator) {
    const wb = new Workbox('/service-worker.js');
    window.workbox = wb;

    const showSkipWaitingPrompt = (event) => {
        console.warn('a new service worker is waiting');
        // `event.wasWaitingBeforeRegister` will be false if this is
        // the first time the updated service worker is waiting.
        // When `event.wasWaitingBeforeRegister` is true, a previously
        // updated service worker is still waiting.
        // You may want to customize the UI prompt accordingly.
        if(Swal.toast == true || !Swal.isVisible()){
            Swal.fire({
                title: 'Refresh Application?',
                text: "A new version is available",
                icon: 'info',
                toast: true,
                position: 'bottom-end',
                showConfirmButton: true,
                confirmButtonText: 'Refresh',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-primary',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    console.warn('a new service worker is switching');
                    // Assuming the user accepted the update, set up a listener
                    // that will reload the page as soon as the previously waiting
                    // service worker has taken control.
                    wb.addEventListener('controlling', (event) => {
                        window.location.reload();
                    });

                    wb.messageSkipWaiting();
                }
            });
        }

    };
    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    wb.addEventListener('waiting', showSkipWaitingPrompt);
    wb.addEventListener('externalwaiting', showSkipWaitingPrompt);

    wb.register();

    // show prompt when client online
    window.addEventListener('offline', () => {
        console.warn('site is offline');
        Toast.fire({
            icon: 'info',
            title: 'You Are Offline',
            text: 'Limited functionality whilst you are offline',
        });

    });

    // show prompt when client offline
    window.addEventListener('online', () => {
        console.warn('site is online');
        Toast.fire({
            icon: 'success',
            title: 'You Are Online',
            text: 'Full functionality has been restored',
        });
    });

    // initialize deferredPrompt for use later to show browser install prompt.
    let deferredPrompt;
    window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        deferredPrompt = e;// Stash the event so it can be triggered later.
    });

    // detect when the PWA was successfully installed
    window.addEventListener('appinstalled', () => {
        deferredPrompt = null; // Clear the deferredPrompt so it can be garbage collected
        console.warn('PWA was installed');
    });

    // show the install prompt
    window.addEventListener('triggerinstallprompt', async () => {
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        console.warn(`User response to the install prompt: ${outcome}`);
        deferredPrompt = null;
    });

    // force stale page to refresh with new content after cache update
    navigator.serviceWorker.addEventListener('message', async (event) => {
        if (event.data.meta === 'workbox-broadcast-update') {
            const {cacheName, updatedURL} = event.data.payload;
            const cache = await caches.open(cacheName);
            const updatedResponse = await cache.match(updatedURL);
            const updatedText = await updatedResponse.text();
            if(!Swal.isVisible()){
                console.warn('broadcasting cache update');
                debounce(2000, true, () => {
                    //needed to prevent spamming of broadcast update
                    //was triggering multiple sms codes being sent at once
                    window.location.reload();
                });
            }
        }
    });

}
