// jquery
import $ from 'jquery';
// assign jquery to window context
window.$ = window.jQuery = $;
import 'jquery-validation';
import 'jquery.browser';
import 'jquery-ui/ui/widgets/datepicker';
import 'inputmask/dist/jquery.inputmask.min.js';
import './includes/sweetalert.js';
import './includes/intltelinput.js';
import './includes/bootstrap.js';
import './includes/jquery-validate.js';
import moment from 'moment';
import './includes/workbox.js'; // service worker pwa
window.moment = moment;
