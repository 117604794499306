import $ from 'jquery';
window.$ = window.jQuery = $;
import 'jquery-validation';

$.validator.addMethod("strongPassword", function (value, element) {
    let password = value;
    if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(.{8,}$)/.test(password))) {
        return false;
    }
    return true;
}, function (value, element) {
    let password = $(element).val();
    if (!(/^(.{8,}$)/.test(password))) {
        //Password must be at least 8 characters long.'
        return 'Password must contain<br>at least 8 characters<br>at least one uppercase<br>at least one lowercase<br>at least one number<br>at least one special character';
    }
    else if (!(/^(?=.*[A-Z])/.test(password))) {
        //Password must contain at least one uppercase.'
        return 'Password must contain<br>at least 8 characters<br>at least one uppercase<br>at least one lowercase<br>at least one number<br>at least one special character';
    }
    else if (!(/^(?=.*[a-z])/.test(password))) {
        //Password must contain at least one lowercase.
        return 'Password must contain<br>at least 8 characters<br>at least one uppercase<br>at least one lowercase<br>at least one number<br>at least one special character';
    }
    else if (!(/^(?=.*[0-9])/.test(password))) {
        //Password must contain at least one number.
        return 'Password must contain<br>at least 8 characters<br>at least one uppercase<br>at least one lowercase<br>at least one number<br>at least one special character';
    }
    else if (!(/^(?=.*[^A-Za-z0-9])/.test(password))) {
        //Password must contain at least one special character, e.g. @#$%&.
        return 'Password must contain<br>at least 8 characters<br>at least one uppercase<br>at least one lowercase<br>at least one number<br>at least one special character';
    }
    return false;
});

$.validator.addMethod("tfn", function (value, element) {
    var tfn = value;
    if (tfn == ''){
        return true;
    }

    var digits = tfn.split('');

    var sum = (digits[0]*1)
            + (digits[1]*4)
            + (digits[2]*3)
            + (digits[3]*7)
            + (digits[4]*5)
            + (digits[5]*8)
            + (digits[6]*6)
            + (digits[7]*9)
            + (digits[8]*10);

    var remainder = sum % 11;

    if(remainder == 0) {
        return true;
    } else {
        return false;
    }
},'Please enter a valid tax file number');

$.validator.addMethod("abn", function (value, element) {
    var abn = value;
    if (abn == ''){
        return true;
    }

    var weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19],
    abn = value.replace(/[^\d]/g, ''),
    result = false;

    // check length is 11 digits
    if (abn.length === 11) {

        // apply ato check method
        var sum = 0,
            weight;

        for (var index = 0; index <= weights.length - 1; index++) {
            weight = weights[index];
            var digit = abn[index] - (index ? 0 : 1);
            sum += weight * digit;
        }

        result = sum % 89 === 0;
    }

    return result;
},'Please enter a valid ABN');

$.validator.addMethod("internationalMobile", function (value, element) {
    // Check if the number starts with a plus symbol
    if (value.charAt(0) !== "+") {
      return false;
    }

    // Remove the plus symbol and whitespace from the number
    var number = value.replace(/\s|\+/g, "");

    // Check if the number contains only digits
    if (!/^\d+$/.test(number)) {
      return false;
    }

    // Check if the number length is between 7 and 16 (excluding the plus symbol and whitespace)
    return number.length >= 7 && number.length <= 16;
}, 'Please enter a valid mobile number including country code.');

