import $ from 'jquery';
window.$ = window.jQuery = $;
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
$.fn.initIntlTelInput = function() {
    var inputs = this; // Get all elements in the jQuery collection
    inputs.each(function(index, input) {
        var errorLabel = $(input).parent().find('label.error:first');

        // here, the index maps to the error code returned from getValidationError - see readme
        var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

        // initialise plugin
        var iti = intlTelInput(input, {
            initialCountry: "au",
            preferredCountries: ["au"],
            formatOnDisplay: false,
        });

        var reset = function() {
            input.classList.remove("is-invalid");
            errorLabel.text("");
            errorLabel.addClass("hide");
            input.value = input.value.replace(/[^0-9]+/,"");
        };

        // on blur: validate
        input.addEventListener('blur', function() {
            reset();
            if (input.value.trim()) {
                if (iti.isValidNumber()) {
                    input.value = iti.getNumber();
                    $(input).closest('form').validate().element($(input));
                } else {
                    input.classList.add("is-invalid");
                    var errorCode = iti.getValidationError();
                    errorLabel.text(errorMap[errorCode]);
                    errorLabel.removeClass("hide");
                }
            }
        });

        // on keyup / change flag: reset
        input.addEventListener('change', reset);
        input.addEventListener('keyup', reset);
    });
};
