import $ from 'jquery';
import 'bootstrap';
window.$ = window.jQuery = $;

// bootstrap tooltips
$(function () {
    $('[data-bs-toggle="tooltip"]').tooltip()
});

// bootstrap popovers
$(function () {
    $('[data-bs-toggle="popover"]').popover()
});

// bootstrap sidebar toggle
window.addEventListener('DOMContentLoaded', event => {
    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    if (sidebarToggle) {
        // Uncomment Below to persist sidebar toggle between refreshes
        // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
        //     document.body.classList.toggle('sb-sidenav-toggled');
        // }
        sidebarToggle.addEventListener('click', event => {
            event.preventDefault();
            document.body.classList.toggle('sb-sidenav-toggled');
            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
        });
    }
});

// bootstrap masonry
import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
// make Masonry a jQuery plugin
jQueryBridget( 'masonry', Masonry, $ );
