import $ from 'jquery';
window.$ = window.jQuery = $;
import Swal from 'sweetalert2';
const swal = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary m-2',
        cancelButton: 'btn btn-secondary m-2',
        denyButton: 'btn btn-dark m-2',
        loader: 'ocl-loader'
    },
    willOpen: ()=> {
        var input = document.querySelector(".forceenter");
            if(input){
                input.addEventListener("keypress", function(event) {
                    // If the user presses the "Enter" key on the keyboard
                    if (event.key === "Enter") {
                        // Cancel the default action, if needed
                        event.preventDefault();
                    }
                });
            }
    },
    loaderHtml: '<img nonce="{{ csp_nonce() }}" class="ocl-spinner" src="/img/ocl-spinner.gif" />',
    buttonsStyling: false,
    reverseButtons: true,
    showCloseButton: true,
    showCancelButton: false,
});
window.Modal = swal;
const toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 6000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
window.Toast = toast;
const loading = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary m-2',
        cancelButton: 'btn btn-secondary m-2',
        denyButton: 'btn btn-dark m-2',
        loader: 'ocl-loader'
    },
    loaderHtml: '<img nonce="{{ csp_nonce() }}" class="ocl-spinner" src="/img/ocl-spinner.gif" />',
    buttonsStyling: false,
    reverseButtons: true,
    title: 'Please Wait',
    showCancelButton: false,
    showCloseButton: false,
    allowOutsideClick: false,
    didOpen: () => {
        Modal.showLoading();
    },
});
window.Loading = loading;
